import React from 'react'

import { TextKey } from '@pure/emu/Antiloop'
import { getFigmaText } from '@pure/libs/TextRepository'

type Props = {
  styleTextKey?: TextKey
  textKey: TextKey
  className?: string
  text?: string
  separator?: React.ReactElement
}

const FigmaTextWithLinebreaks: React.FC<Props> = ({
  styleTextKey,
  text: _text,
  textKey,
  className,
  separator = <span />
}) => {
  const { style } = styleTextKey || textKey || {}
  const text = typeof _text === 'string' ? _text : getFigmaText(textKey)
  const texts = text.split('\n')

  return (
    <>
      {texts.map((text, key) => {
        return (
          <React.Fragment key={key}>
            <span dangerouslySetInnerHTML={{ __html: text }} className={className} style={{ ...style }} />
            {separator}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default FigmaTextWithLinebreaks
