import { useNavigate } from 'react-router-dom'

import RoutePath from '@contracts/enums/RoutePath'
import { Session } from '@contracts/types/Session'

import { navigateToReceiptChildView } from '@pay/libs/ReceiptViewHelper'

import Box from './Box'
import { ReceiptViewEmailButton, ReceiptViewSMSButton } from './ReceiptButtonsComponent'

export function ReceiptButtonsPublic({ session }: { session: Session }): JSX.Element | undefined {
  const navigate = useNavigate()
  const onClickSendSMS = () => navigateToReceiptChildView({ route: RoutePath.RECEIPT_SEND_SMS, navigate, session })

  const onClickSendEmail = () => navigateToReceiptChildView({ route: RoutePath.RECEIPT_SEND_EMAIL, navigate, session })

  return (
    <Box fullWidth>
      <ReceiptViewSMSButton onClick={onClickSendSMS} />
      <Box top fullWidth>
        <ReceiptViewEmailButton onClick={onClickSendEmail} />
      </Box>
    </Box>
  )
}
