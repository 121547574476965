import styled from '@emotion/styled'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { TextKey } from '@pure/emu/Antiloop'

import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function MessageSuccess({ onClose, textKey }: { textKey: TextKey; onClose?: () => void }) {
  return (
    <Container fullPadding direction="row" align="center" fullWidth justify="space-between" spacing={Spacings.s}>
      <Box>
        <FigmaImageContainer imageKey={Images.successIcon} />
      </Box>
      <Box left right multiline>
        <FigmaText textKey={textKey} />
      </Box>
      <Box onClick={onClose} link visibility={onClose ? 'visible' : 'hidden'}>
        <FigmaText
          styleTextKey={Texts.textNotificationsNotificationPaymentErrorReturned}
          textKey={Texts.textButtonsTxtButtonClose}
        />
      </Box>
    </Container>
  )
}

export const Container = styled(Box)`
  border-radius: var(--borderRadius-m, ${BorderRadiusesPx.lightlyRounded});
  border: 1px solid var(--toast-toast-success, ${Colors.baseDarkGreen});
  background: #eefff9;

  /* Shadow */
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.1);
`
