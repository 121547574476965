import React from 'react'

import Box from '@pay/components/Box'
import { getUrl } from '@pay/libs/FortnoxOAuthHelper'

export default function FortnoxOauthPage() {
  React.useEffect(() => {
    window.location.href = getUrl()
  }, [])

  return <Box />
}
