import PublicAssets from '@contracts/enums/PublicAssets'
import { WebSpacings } from '@contracts/enums/Spacings'

import { toPx } from '@pure/libs/Common'

const RECEIPT_DESKTOP_MAX_WIDTH = 300

export const getUrlToPublicAsset = (asset: PublicAssets, config: { payBaseUrl: string }) => {
  return `${config.payBaseUrl}${asset}`
}

export function getReceiptWidth({ isDesktop, isEmailClient }: { isDesktop?: boolean; isEmailClient?: boolean }) {
  if (isDesktop) return RECEIPT_DESKTOP_MAX_WIDTH
  if (isEmailClient) return RECEIPT_DESKTOP_MAX_WIDTH

  if (typeof window !== 'undefined') return window.screen.width - 2 * toPx(WebSpacings.m)

  throw new Error('Unsported platform')
}
