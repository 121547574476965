import invariant from 'invariant'
import { Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { Spacings } from '@contracts/enums/Spacings'

import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import Box from '@pay/components/Box'
import FigmaImageContainer from '@pay/components/FigmaImageContainer'
import FigmaText from '@pay/components/FigmaText'
import { Layout } from '@pay/components/Layout'
import { ReceiptViewEmailButton } from '@pay/components/ReceiptButtonsComponent'
import TextFieldForm from '@pay/components/TextFieldForm'
import { useForm } from '@pay/hooks/useForm'
import { sendReceiptForSession } from '@pay/libs/CloudFunctionsApiHandler'
import { HardCodedTexts } from '@pay/libs/HardCodedTexts'
import { success } from '@pay/libs/ToastHelper'
import { Variables } from '@pay/libs/Variables'

const ENTER_EMAIL = 'Enter email'
const ValidationSchema = Yup.object()
  .shape({
    email: Yup.string().required(ENTER_EMAIL).typeError(ENTER_EMAIL).email(ENTER_EMAIL)
  })
  .required()

export const ReceiptSendEmailPage = () => {
  const location = useLocation()
  const { state: locationState } = location
  const { session } = locationState

  const formProps = useForm(ValidationSchema, { defaultValues: { email: '' } })

  const onSubmit = formProps.handleSubmit(({ email }) =>
    Promise.resolve().then(() => {
      const sessionId = session?.id
      invariant(sessionId, '!sessionId')
      return sendReceiptForSession({ sessionId, email }).then(() =>
        success({ textKey: Texts.textNotificationsReceiptMailSent })
      )
    })
  )

  const navigate = useNavigate()

  return (
    <Layout>
      <Box fullWidth fullPadding>
        <Box onClick={() => navigate(-1)} pointer>
          <FigmaImageContainer imageKey={Images.arrowLeftGrey} />
        </Box>
        <Box top>
          <FigmaText
            textKey={HardCodedTexts.textPaymentsSwishEnterPhoneNumber}
            text={getFigmaTextFromVariable(Variables['VariableID:734:12129'])}
          />
        </Box>
        <Box top spacing={Spacings.xs} fullWidth>
          <Controller
            control={formProps.control}
            name="email"
            render={({ field, fieldState }) => {
              return <TextFieldForm {...field} fullWidth fieldState={fieldState} autoFocus />
            }}
          />
        </Box>
        <Box top fullWidth>
          <ReceiptViewEmailButton onClick={onSubmit} />
        </Box>
      </Box>
    </Layout>
  )
}
