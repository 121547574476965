import { Column } from '@react-email/column'
import { Row } from '@react-email/row'

import { Spacings } from '@contracts/enums/Spacings'
import { Session } from '@contracts/types/Session'

import Texts from '@pure/assets/PayTexts'
import { formatPrice } from '@pure/libs/ReceiptHelper'

import FigmaText from '@web-components/shared/FigmaText'
import { FigmaTextWithStyle } from '@web-components/shared/FigmaTextWithStyle'

import { EmailBox as Box } from './EmailBox'

export function EmailReceiptSummary({ session }: { session: Session }) {
  return (
    <Box fullWidth>
      <Box fullWidth>
        <Row>
          <Column>
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptServiceFee} />
          </Column>
          <Column align="right">
            <FigmaText
              textKey={Texts.textPaymentsReceiptReceiptServiceFee}
              text={formatPrice(session?.parkingSession?.currentPayment?.fees?.administrationFeeInclVAT)}
            />
          </Column>
        </Row>
      </Box>
      <Box top spacing={Spacings.xs} fullWidth>
        <Row>
          <Column>
            <FigmaTextWithStyle type="body2Bold" textKey={Texts.textPaymentsReceiptReceiptTotalCost} />
          </Column>
          <Column align="right">
            <Row>
              <Column align="right">
                <FigmaTextWithStyle
                  type="body2Bold"
                  textKey={Texts.textPaymentsReceiptCssReceiptPriceCharging}
                  text={formatPrice(session?.parkingSession?.currentPayment?.fees?.totalFeeIncludedVAT)}
                />
              </Column>
            </Row>
          </Column>
        </Row>
      </Box>

      <Box top spacing={Spacings.xs} fullWidth>
        <Row>
          <Column>
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptVat} />
          </Column>
          <Column align="right">
            <FigmaText
              textKey={Texts.textPaymentsReceiptReceiptVat}
              text={formatPrice(session?.parkingSession?.currentPayment?.fees?.totalVAT)}
            />
          </Column>
        </Row>
      </Box>
    </Box>
  )
}
