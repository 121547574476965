import styled from '@emotion/styled'
import React from 'react'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Site } from '@contracts/types/Site'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import { getFigmaText, getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import { getFreeParkingDuration } from '@pay/helpers/HomeLandingHelper'
import useIsMobile, { useIsDesktop } from '@pay/hooks/useIsMobile'
import { useParking } from '@pay/hooks/useParking'
import { useSite } from '@pay/hooks/useSite'
import { HomeViewProps } from '@pay/libs/HomeHelper'
import ImagesPay from '@pay/libs/Images'
import Texts from '@pay/libs/Texts'
import { Variables } from '@pay/libs/Variables'

import { AppStoreButtons } from './AppStoreButtons'
import Box from './Box'
import Button from './Button'
import FigmaImage from './FigmaImage'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText, { FigmaTextProps } from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { LAYOUT_CHILDREN_MAX_WIDTH } from './HardcodedSizes'
import { LayoutHeaderDesktop } from './LayoutHeaderApp'
import Loader from './Loader'
import { StationInfoDrawer } from './StationInfoDrawer'

export const HomeLanding = (props: HomeViewProps) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const { data } = useParking(window)
  const parking = data?.data?.data
  const [showStationInfo, setShowStationInfo] = React.useState(false)
  const { data: site, isLoading: isLoadingSite } = useSite(String(parking?.id))

  const onPressPayButton = () => {
    if (!isLoadingSite && site?.enablePayments) props.onPressContinue(props.homeMachineState)
  }

  const freeTimeText = getFreeParkingDuration(site as Site)

  return (
    <Box fullWidth align="center">
      <StationInfoDrawer open={showStationInfo} onClose={() => setShowStationInfo(false)} site={site} />
      <LayoutHeaderDesktop onClickHeaderLogo={undefined as any} />
      <Box fullWidth style={{ maxWidth: isDesktop ? LAYOUT_CHILDREN_MAX_WIDTH : undefined }}>
        <Box fullWidth top align="center" left={isMobile} right={isMobile}>
          <PurpleContainer
            fullWidth
            fullPadding
            spacing={Spacings.xs}
            style={{
              backgroundColor:
                site?.id && site?.enablePayments && freeTimeText !== false ? 'rgba(94, 83, 225, 1)' : 'transparent'
            }}
          >
            <ParkingInformation direction="row" align="center" fullPadding spacing={Spacings.s} fullWidth>
              <FigmaImageContainer imageKey={ImagesPay.parkingPurple} />
              <Box left>
                {props.isLoading && <Loader size={Spacings.s} />}
                {!props.isLoading && (
                  <FigmaTextWithStyle
                    type="body1Regular"
                    text={parking?.name || ''}
                    Component={FigmaTextWithLinebreaks as (props: FigmaTextProps) => JSX.Element}
                  />
                )}
              </Box>
            </ParkingInformation>
            {!!site?.id && site?.enablePayments && freeTimeText !== false && (
              <Box fullWidth direction="row" align="center" justify="space-between" fullPadding spacing={Spacings.s}>
                <Box fullWidth gap={Spacings.xxs}>
                  <FigmaText textKey={Texts.textPaymentstextComplimentaryParking} />
                  <Box fullWidth direction="row" align="center" justify="space-between">
                    <Box width={isMobile ? '65%' : '100%'}>
                      {typeof freeTimeText === 'string' ? (
                        <FigmaText textKey={Texts.textPaymentstextXhoursFreeParking} text={freeTimeText} />
                      ) : (
                        <ClickableBox onClick={() => setShowStationInfo(true)}>
                          <FigmaText textKey={Texts.textPaymentsEnjoyFreeParking} text={freeTimeText?.header} />
                          <FigmaText
                            textKey={Texts.textPaymentsFreeParkingLimitations}
                            text={freeTimeText?.subheader}
                          />
                        </ClickableBox>
                      )}
                    </Box>
                    <FigmaImageContainer imageKey={ImagesPay.stopwatchPurple} />
                  </Box>
                </Box>
              </Box>
            )}
          </PurpleContainer>
          {!site?.enablePayments && (
            <BlueInformationBox fullWidth>
              <Box fullWidth direction="row" align="center" gap={Spacings.s}>
                <FigmaImage imageKey={Images.info} />
                <Box gap={Spacings.xxs}>
                  <FigmaText textKey={Texts.textPaymentsQrPaymentCurrentlyFree} />
                  <FigmaText textKey={Texts.textPaymentsQrPaymentNoPaymentRequired} />
                </Box>
              </Box>
            </BlueInformationBox>
          )}
          {site?.enablePayments && (
            <Box fullWidth top>
              <Box fullWidth>
                <Button fullWidth onClick={onPressPayButton}>
                  <Box direction="row">
                    <FigmaImageContainer imageKey={Images.homeLandingCtaCreditCard} />
                    <Box left spacing={Spacings.xxs}>
                      <FigmaTextWithStyle
                        type="body1Bold"
                        color="white"
                        text={getFigmaTextFromVariable(Variables['VariableID:953:10194'])}
                      />
                    </Box>
                  </Box>
                </Button>
              </Box>
              <Box top fullWidth spacing={Spacings.xs}>
                <Box fullWidth align="center" top bottom spacing={Spacings.xs} onClick={() => setShowStationInfo(true)}>
                  <ClickableBox direction="row" align="center">
                    <FigmaImageContainer imageKey={ImagesPay.informationIconDark} />
                    <Box left spacing={Spacings.xxs}>
                      <FigmaText textKey={Texts.textPaymentsPricesAndParkingInformation} />
                    </Box>
                  </ClickableBox>
                </Box>
              </Box>
            </Box>
          )}
          <Box fullWidth position="absolute" style={{ bottom: '10%' }}>
            <Box fullWidth align="center">
              <FigmaTextWithStyle
                text={getFigmaText(Texts.textPaymentsQrPaymentMarketTheApp)}
                type="body2Regular"
                color="purple5"
              />
              <Box top spacing={Spacings.s} position="relative">
                <Box position="absolute" style={{ left: `-${Spacings.xl}`, top: `${Spacings.xs}` }}>
                  <FigmaImageContainer imageKey={Images.headerArrow} />
                </Box>
                <AppStoreButtons width={Images.homeLandingAppStore.width} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const PurpleContainer = styled(Box)`
  border-radius: ${BorderRadiusesPx.rounded};
`

const ParkingInformation = styled(Box)`
  background: var(--surface-surface-information, ${Colors.purpleSurface});
  border-radius: var(--radius-radius-lighly-rounded, ${BorderRadiusesPx.lightlyRounded});
`

const ClickableBox = styled(Box)`
  cursor: pointer;
`

const BlueInformationBox = styled(Box)`
  margin-top: ${Spacings.s};
  padding: ${Spacings.s};
  border: 1px solid rgba(35, 145, 207, 1);
  border-radius: ${BorderRadiusesPx.minimum};
  background-color: rgba(233, 245, 252, 1);
  box-shadow: 0px 4px 8px 0px rgba(53, 51, 85, 0, 0.1);
`
