import styled from '@emotion/styled'
import MaterialTextField, { TextFieldProps } from '@mui/material/TextField'

import { TextKey } from '@pure/emu/Antiloop'
import { getFigmaText } from '@pure/libs/TextRepository'

import { createShouldForwardProp } from '@pay/libs/EmotionHelper'

export type TextFieldOutlinedProps = {
  labelTextKey?: TextKey
  placeholderTextKey?: TextKey
  pointer?: boolean
  className?: string
  Component?: React.FC<TextFieldProps>
} & TextFieldProps

export const TextField = (props: TextFieldOutlinedProps) => {
  const { labelTextKey, placeholderTextKey, Component = BaseTextField, ...rest } = props

  return (
    <Component
      variant="outlined"
      size="small"
      label={labelTextKey ? getFigmaText(labelTextKey) : ''}
      placeholder={placeholderTextKey ? getFigmaText(placeholderTextKey) : undefined}
      InputLabelProps={{ shrink: false }}
      {...rest}
    />
  )
}

export const TextFieldOutlined = TextField

// Is duplicated in see TextFieldOutlinedNumberInputStyles
export const BaseTextFieldStyles = `
  & .MuiOutlinedInput-notchedOutline {
    border-radius: var(--radius-radius-lighly-rounded, 0.5rem);
    border: 1px solid var(--neutrals-netutral-40, #DFE2E6);
  }

  & legend {
    display: none;
  }

  & fieldset {
    top: 0;
  }

  background-color: white;
  cursor: pointer;
`

export const BaseTextField = styled(MaterialTextField, {
  shouldForwardProp: createShouldForwardProp('multiline')
})`
  ${BaseTextFieldStyles}
`
