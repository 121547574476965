import styled from '@emotion/styled'

import { Spacings } from '@contracts/enums/Spacings'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { getFigmaText } from '@pure/libs/TextRepository'

import { AppStoreButtons } from './AppStoreButtons'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export function LayoutHeaderDesktop({ onClickHeaderLogo }) {
  return (
    <HeaderContainer fullWidth fullPadding align="center">
      <LayoutHeaderLogo onClick={onClickHeaderLogo} />
    </HeaderContainer>
  )
}

export function LayoutHeaderApp({ onClickHeaderLogo }) {
  return (
    <HeaderContainer fullWidth fullPadding spacing={Spacings.xs} alignText="right">
      <FigmaTextWithStyle
        type="captionItallic"
        text={getFigmaText(Texts.textPaymentsQrPaymentMarketTheApp)}
        color="white"
      />
      <Box direction="row" justify="space-between" fullWidth>
        <Box spacing={Spacings.s} top>
          <LayoutHeaderLogo onClick={onClickHeaderLogo} />
        </Box>
        <Box>
          <Box spacing={Spacings.xs} top position="relative">
            <Box position="absolute" style={{ left: `-${Spacings.xl}`, top: `${Spacings.xs}` }}>
              <FigmaImageContainer imageKey={Images.headerArrow} />
            </Box>
            <AppStoreButtons />
          </Box>
        </Box>
      </Box>
    </HeaderContainer>
  )
}

export const HeaderContainer = styled(Box)`
  background-color: ${Colors.primary};
`

function LayoutHeaderLogo({ onClick }) {
  return (
    <Box pointer onClick={onClick}>
      <FigmaImageContainer imageKey={Images.headerLogoAndText} />
    </Box>
  )
}
