import invariant from 'invariant'
import useQueryParams, { PaymentCallbackQueryParams } from 'pure/libs/useQueryParams'
import { useEffect } from 'react'

import { decryptClientReceiptPayload, shouldShowReceiptExpiredUrlPage } from '@pure/libs/ReceiptHelper'

import Box from '@pay/components/Box'
import { LoadingPage } from '@pay/components/LoadingPage'
import { Receipt } from '@pay/components/Receipt'
import { ReceiptButtons } from '@pay/components/ReceiptButtons'
import { ReceiptExpiredUrl } from '@pay/components/ReceiptExpiredUrl'
import { useHikerSession } from '@pay/hooks/useHikerSession'
import { captureAndNotifyError } from '@pay/libs/ErrorHelper'

export const ReceiptPage = () => {
  const { hash } = useQueryParams<PaymentCallbackQueryParams>()
  const clientReceiptPayload = decryptClientReceiptPayload(hash)
  const sessionId: any = clientReceiptPayload?.sessionId

  const shouldShowExpiredUrlPage = shouldShowReceiptExpiredUrlPage(clientReceiptPayload)

  const { data: session, isLoading: isLoadingSession } = useHikerSession(sessionId)

  useEffect(() => {
    if (!sessionId) return captureAndNotifyError(new Error(`ReceiptView: Cant find session for id: ${sessionId}`))
  }, [sessionId])

  if (shouldShowExpiredUrlPage) return <ReceiptExpiredUrl clientReceiptPayload={clientReceiptPayload} />
  if (!session) return <ReceiptExpiredUrl clientReceiptPayload={clientReceiptPayload} />
  if (isLoadingSession) return <LoadingPage LayoutComponent={Box} />

  invariant(clientReceiptPayload, '!clientReceiptPayload')

  return (
    <Receipt
      clientReceiptPayload={clientReceiptPayload}
      enableSuccessMessage={false}
      session={session}
      buttonElement={<ReceiptButtons session={session} />}
    />
  )
}
