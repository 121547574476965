import { useEffect, useState } from 'react'

import useAppState, { State } from '@pure/libs/hooks/useAppState'

import { ReducerState } from '@web-js/hooks/useFirestoreQueryHelper'

const DEFAULT_OPTIONS = { enabled: true, key: '' }
export const useAsyncFunction = <T>(
  fn: (state: State) => Promise<T>,
  { enabled = true, key = '' }: { enabled?: boolean; key?: string } = DEFAULT_OPTIONS
) => {
  const { state } = useAppState()
  const [query, setQuery] = useState<ReducerState<T>>(getDefaultsState<T>())

  useEffect(() => {
    if (!enabled) return
    Promise.resolve(setQuery({ ...query, isLoading: true }))
      .then(() => fn(state))
      .then((data) => setQuery({ ...query, data, isLoading: false, error: undefined }))
      .catch((err) => setQuery({ ...query, isLoading: false, error: err, data: undefined }))
  }, [enabled, key])

  return query
}

export function getDefaultsState<T>(): ReducerState<T> {
  return {
    status: 'idle',
    data: undefined,
    error: undefined,
    isLoading: true //useSession assumes this is true
  }
}
