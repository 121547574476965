import { getApiV2Parking } from '@web-js/libs/ApiHandler'
import { getParkIdFromUrl } from '@web-js/libs/getParkIdFromUrl'

import { useAsyncFunction } from './useAsyncFunction'

export const useParking = (window: Window) => {
  // TODO WRITE TEST should account for unknonw location https://drifterworldgroup.slack.com/archives/D063R4H3J64/p1704220084782289
  const parkId = getParkIdFromUrl(window.location?.href)
  return useParkingForParkId(parkId)
}

export const useParkingForParkId = (parkId?: number) =>
  useAsyncFunction(() => getApiV2Parking({ parkId: parkId as number }), { enabled: !!parkId, key: parkId?.toString() })

export const getParking = (window: Window) => {
  // TODO WRITE TEST should account for unknonw location https://drifterworldgroup.slack.com/archives/D063R4H3J64/p1704220084782289
  const parkId = getParkIdFromUrl(window.location?.href)
  return parkId ? getApiV2Parking({ parkId }) : Promise.resolve()
}
