import { OnSessionClosedRequest, Session, SessionOld } from '@contracts/types/Session'

export function getOnSessionClosedRequestFromSession(session: Session | SessionOld): OnSessionClosedRequest {
  return {
    sessionId: session.id,
    fees: {
      parkingFee:
        (session as Session).parkingSession?.currentPayment?.fees.parkingFee ||
        //@ts-ignore
        (session as SessionOld).backendSession?.park_session?.costs?.parking_cost ||
        //@ts-ignore
        (session as SessionOld).backendSession?.park_session?.costs?.normal?.cost ||
        0,
      chargingFee: 0
    }
  }
}
