import { NavigateFunction } from 'react-router-dom'

import RoutePath from '@contracts/enums/RoutePath'
import { Session } from '@contracts/types/Session'

export function navigateToReceiptChildView({
  route,
  session,
  navigate
}: {
  route: RoutePath
  session: Session
  navigate: NavigateFunction
}) {
  navigate(route, { state: { session } })
}
