export type SessionEventTypePrioritized = 'offense' | 'permit' | 'free' | 'parking'
export type SessionEventType = SessionEventTypePrioritized | 'session'

export const SessionEventTypePriority = ['offense', 'permit', 'free', 'parking'] as SessionEventTypePrioritized[]

export const SessionEventTypesWithParkingFee = ['permit', 'parking'] as SessionEventType[]

export const SessionEventTypePriorityMap = SessionEventTypePriority.reduce(
  (a, type) => ({ ...a, [type]: true }),
  {} as { [key in SessionEventTypePrioritized]: boolean }
)
