import { en } from '@consts/Locale'

import { Language } from '@pure/emu/Antiloop'

export const getLanguageCode = (): Language => en

export const useLanguage = getLanguageCode

export function getDefaultLanguageCode(): Language {
  return en
}
