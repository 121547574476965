import { DeliveryOption, Session } from '@contracts/types/Session'

export function getDeliveryOption(receipt: Session): DeliveryOption | undefined {
  const phone = getPhoneForReceiptDelivery(receipt)
  const email = getEmailForReceiptDelivery(receipt)

  if (phone) return DeliveryOption.SMS
  if (email) return DeliveryOption.EMAIL
  return undefined
}

export function getEmailForReceiptDelivery(session: Session) {
  const { stripePaymentIntent } = session
  const user = session.parkingSession?.user
  if (!stripePaymentIntent) return undefined
  const { paymentIntent, paymentMethod } = stripePaymentIntent
  const emailFromStripe = paymentIntent?.receipt_email || paymentMethod?.billing_details?.email
  if (emailFromStripe) return emailFromStripe
  return user?.email
}

export function getPhoneForReceiptDelivery(session: Session) {
  const payer_alias = session.swishPaymentIntent?.payer_alias
  if (payer_alias) return `+${payer_alias}`
  return session.parkingSession?.user?.phone
}
