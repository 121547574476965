// TODO: this is (big) part of the main app config, right now it is shared because other modules are using it but it should be moved somewhere to the top (possibly new place like @app)

import 'source-map-support/register'
export type Config = typeof defaultConfig

type Environment = 'local' | 'prod'

const defaultConfig = {
  payBaseUrl: 'https://pay.drifterworld.se'
}

const configs: { [property in Environment]: Config } = {
  local: {
    ...defaultConfig
  },
  prod: {
    ...defaultConfig
  }
}

export const config: Config = configs.prod
