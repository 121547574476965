import { EUROPE_STOCKHOLM } from '@consts/DateConsts'

import { DefaultSiteSessionLogic } from '@contracts/types/DefaultSite'
import { ParkingSession } from '@contracts/types/ParkingSession'
import { SessionSite } from '@contracts/types/Session'
import { Site } from '@contracts/types/Site'
import { SiteSessionLogic } from '@contracts/types/SiteSessionLogic'

export function getTimezoneForSite(site?: SessionSite) {
  return site?.timezone || EUROPE_STOCKHOLM
}

export const getSessionLogic = ({
  site,
  parkingSession
}: {
  site?: Site
  parkingSession?: ParkingSession
}): Required<SiteSessionLogic> => {
  return { ...DefaultSiteSessionLogic, ...site?.sessionLogic, ...parkingSession?.sessionLogic }
}

export const getSiteCapacity = (site: Site): number => {
  const capacity =
    site?.segments?.reduce((count, segment) => {
      return count + (segment.slots?.length ?? 0)
    }, 0) ?? 0

  return capacity
}
