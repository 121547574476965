import { HHmm } from '@consts/DateConsts'

import { Session } from '@contracts/types/Session'

import { DayJS } from '@pure/libs/DayJsHelper'
import { getTimezoneForSite } from '@pure/libs/SiteHelper'

export function getParkingPeriodText(session: Session, now = DayJS().format()) {
  const timezone = getTimezoneForSite(session?.site)
  const started_at = session?.isHikerSession
    ? session?.parkingSession?.startedAt
    : // @ts-ignore
      session?.backendSession?.park_session?.started_at

  const finishDate = session?.isHikerSession
    ? session?.parkingSession?.endedAt || now
    : // @ts-ignore
      session?.backendSession?.park_session?.ended_at || now

  if (!started_at) return ''

  const startedAt = DayJS(started_at).tz(timezone).format(HHmm)
  const endedAt = DayJS(finishDate).tz(timezone).format(HHmm)

  const duration = DayJS.duration(DayJS(finishDate).diff(started_at)).format(HHmm)

  return `${startedAt} - ${endedAt} (${duration})`
}
