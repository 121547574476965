import dayjs from 'dayjs'

import HashEncryptionVersion from '@contracts/enums/HashEncryptionVersion'
import RoutePath from '@contracts/enums/RoutePath'
import { SendReceiptRequest } from '@contracts/types/CloudFunctionTypes'
import { Session } from '@contracts/types/Session'

import { Language } from '@pure/emu/Antiloop'
import { addQueryParamsToUrl } from '@pure/libs/addQueryParamsToUrl'
import { receiptPayloadMocked } from '@pure/libs/ReceiptPayloadMocked'
import { PaymentCallbackQueryParams, ReceiptQueryParams } from '@pure/libs/useQueryParams'

export type ClientReceiptPayload = { createdAt: string; sessionId: string; regNo?: string; iat?: number }

// TODO WRITE TEST, should show success badge for callback urls
export const getSuccessCallbackReceiptUrl = (sessionId: string, config, now = dayjs().format()): string =>
  getHashedReceiptUrl({
    sessionId,
    config,
    routePath: RoutePath.RECEIPT_DETAIL,
    now,
    success: 'true',
    iat: dayjs(now).unix()
  })

// TODO WRITE TEST, dont show "Successful payment" notification if we only click on receipt details
export const getReceiptDetailsUrlForApp = (sessionId: string, config, now = dayjs().format()): string =>
  getHashedReceiptUrl({ sessionId, config, routePath: RoutePath.RECEIPT_DETAIL, now, iat: dayjs(now).unix() })

// We need to take in config here because functions folder use this file
export const _getReceiptUrl = (queryParams: ReceiptQueryParams, config): string =>
  addQueryParamsToUrl<ReceiptQueryParams>(config.payBaseUrl + RoutePath.RECEIPT_DETAIL, queryParams)

export function getSendReceiptRequest(emailOrPhoneNo: string, p: ClientReceiptPayload): SendReceiptRequest {
  const { sessionId } = p
  if (emailOrPhoneNo.includes('@')) return { sessionId, email: emailOrPhoneNo }
  return { sessionId, phone: emailOrPhoneNo }
}

export const getHashedReceiptUrl = ({
  sessionId,
  config = { payBaseUrl: '' },
  now = dayjs().format(),
  routePath = RoutePath.RECEIPT,
  regNo,
  success,
  iat = dayjs().unix()
}: {
  sessionId: string
  config?: { payBaseUrl: string }
  now?: string
  routePath?: RoutePath
  regNo?: string
  success?: string
  iat?: number
}): string =>
  addQueryParamsToUrl<PaymentCallbackQueryParams>(config.payBaseUrl + routePath, {
    hash: encryptClientReceiptPayload({ createdAt: dayjs(now).format('YYYY-MM-DD'), sessionId, regNo, iat }),
    success
  })

const _getHashEncryptionVersion = (hash: string): HashEncryptionVersion => {
  if (hash.includes('.')) {
    return HashEncryptionVersion.v2
  }
  return HashEncryptionVersion.v1
}

export const encryptClientReceiptPayload = (
  payload: ClientReceiptPayload,
  version: HashEncryptionVersion = HashEncryptionVersion.v1
) => {
  switch (version) {
    case HashEncryptionVersion.v1:
      // @ts-ignore
      delete payload?.iat
      return btoa(JSON.stringify(payload))

    case HashEncryptionVersion.v2:
      return '' // TODO use cloud function GetSecureHash to encrypt data
  }
}

// Will lbe changed to jwt enrtyption / decryption
export const decryptClientReceiptPayload = (
  encrypted: string,
  f = {
    error: console.error
  }
): ClientReceiptPayload | undefined => {
  try {
    const version = _getHashEncryptionVersion(encrypted)

    switch (version) {
      case HashEncryptionVersion.v1: {
        // @ts-ignore
        const obj = JSON.parse(atob(encrypted))
        if (!obj.createdAt || !obj.sessionId) return
        return obj
      }
      case HashEncryptionVersion.v2:
        // TODO use cloud function VerifySecureHash to decrypt data
        return receiptPayloadMocked as ClientReceiptPayload

      default:
        throw new Error(`decryptClientReceiptPayload: unsupported hash encryption version: ${version}`)
    }
  } catch (e) {
    f.error('decryptClientReceiptPayload', e)
    return
  }
}

export function shouldShowReceiptExpiredUrlPage(clientReceiptPayload: ClientReceiptPayload | undefined) {
  return !clientReceiptPayload || dayjs(clientReceiptPayload.createdAt).isBefore(dayjs().add(-1, 'month'))
}

export function formatPrice(
  price: number | undefined,
  opts?: { enableCurrency?: boolean; language?: Language }
): string {
  const { enableCurrency = true } = opts || {}

  const text = `${price?.toFixed(2) || 0} ${enableCurrency ? getCurrencyText() : ''}`
  // TODO WRITE TEST, should format price for en
  // TODO WRITE TEST, should format price for sv
  return text.replace('.', ',')
}

export function formatPriceForUnpayedSession(price: number): string {
  // TODO WRITE TEST, should format 0.99140958945905490 to 0.99
  const text = `${price.toFixed(2)} kr`
  // TODO WRITE TEST, should format price for en
  // TODO WRITE TEST, should format price for sv
  return text.replace('.', ',')
}

export function getCurrencyText(): string {
  // TODO Add support for other currencies
  return 'kr'
}

export const useIsPayed = (receipt?: Partial<Session>): boolean | undefined => {
  // TODO WRITE TEST, if user has a broen swishPaymentIntent and a successfull stripePaymentIntent, should return true
  if (receipt?.stripePaymentIntent) return true
  if (receipt?.swishPaymentIntent?.status === 'REQUEST_COMPLETED') return true
  if (receipt?.swishPaymentIntent?.status === 'REQUEST_CREATED') return undefined

  return false
}

export function getSessionFromPublicSession(sessionPublic: Session): Session | undefined {
  return (sessionPublic.parkingSession?.plate ? sessionPublic : undefined) as Session
}
