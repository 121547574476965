import { Column } from '@react-email/column'
import { Row } from '@react-email/row'

import { Spacings } from '@contracts/enums/Spacings'
import { PaymentOptions } from '@contracts/types/PaymentOptions'
import { Session } from '@contracts/types/Session'

import Texts from '@pure/assets/PayTexts'

import FigmaText from '@web-components/shared/FigmaText'

import { EmailBox as Box } from './EmailBox'

export function EmailReceiptPaymentInformation({ sessionPublic }: { sessionPublic: Session }) {
  const paymentOption = sessionPublic.payment?.method?.paymentOption
  const transactionId = sessionPublic.id

  const paymentMethodText =
    paymentOption === PaymentOptions.Billecta ? 'Invoice' : paymentOption === PaymentOptions.Stripe ? 'Card' : 'N/A'

  return (
    <Box fullWidth>
      <FigmaText textKey={Texts.textPaymentsReceiptReceiptPaymentInformation} />
      <Box fullWidth top spacing={Spacings.xs} justify="space-between">
        <Row>
          <Column>
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptPaymentWith} />
          </Column>
          <Column align="right">
            {paymentMethodText && (
              <FigmaText textKey={Texts.textPaymentsReceiptReceiptPaymentWith} text={paymentMethodText} />
            )}
            {!paymentMethodText && <FigmaText textKey={Texts.textPaymentsReceiptReceiptPaymentWith} text="N/A" />}
          </Column>
        </Row>
      </Box>
      <Box fullWidth top spacing={Spacings.xs} justify="space-between">
        <Row>
          <Column>
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptTransactionId} />
          </Column>
          <Column align="right">
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptTransactionId} text={transactionId?.toString() || ''} />
          </Column>
        </Row>
      </Box>
      <Box fullWidth top spacing={Spacings.xs} justify="space-between">
        <Row>
          <Column>
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptCompanyName} />
          </Column>
          <Column align="right">
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptOrgNo} />
          </Column>
        </Row>
      </Box>
      <Box fullWidth top spacing={Spacings.xs}>
        <FigmaText textKey={Texts.textPaymentsReceiptCssReceiptDetailsStreetName} />
      </Box>
    </Box>
  )
}
