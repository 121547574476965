import useQueryParams, { FortnoxOauthRedirectURIQueryParams } from 'pure/libs/useQueryParams'
import React from 'react'

import Texts from '@pure/assets/PayTexts'

import Box from '@pay/components/Box'
import { createFortnoxCredentials } from '@pay/libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '@pay/libs/ErrorHelper'
import { success } from '@pay/libs/ToastHelper'

export const FortnoxOAuthRedirectPage = () => {
  const qp = useQueryParams<FortnoxOauthRedirectURIQueryParams>()
  const { code, state } = qp

  React.useEffect(() => {
    // if (state !== OAUTH_STATE) return captureAndNotifyError(new Error('state !== OAUTH_STATE'))
    if (code) {
      success({ textKey: Texts.textNotificationsReceiptSmsSent, text: 'Success' })
      createFortnoxCredentials({ code }).catch(captureAndNotifyError)
    }
  }, [])

  return (
    <Box fullWidth align="center" top>
      {qp.error && JSON.stringify(qp)}
    </Box>
  )
}
