import styled from '@emotion/styled'

import { Spacings } from '@contracts/enums/Spacings'

import Texts from '@pure/assets/PayTexts'

import Box from './Box'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export function HomeLandingInfo() {
  return (
    <Container fullPadding spacing={Spacings.m}>
      <FigmaText textKey={Texts.textPaymentsQrCodePaymentInformationRow1} />
      <Box direction="row" align="center" top spacing={Spacings.s}>
        <NumberContainer text="1" />
        <Box left>
          <FigmaText textKey={Texts.textPaymentsQrCodePaymentEnterRegNo} />
        </Box>
      </Box>
      <Box direction="row" align="center" top spacing={Spacings.s}>
        <NumberContainer text="2" />
        <Box left>
          <FigmaText textKey={Texts.textPaymentsQrCodePaymentInfoStep2} />
        </Box>
      </Box>
      <Box direction="row" align="center" top spacing={Spacings.s}>
        <NumberContainer text="3" />
        <Box left>
          <FigmaText textKey={Texts.textPaymentsQrCodePaymentInfoStep3} />
        </Box>
      </Box>
    </Container>
  )
}

export const Container = styled(Box)`
  border-radius: var(--spacing-spacing-sm, 0.5rem);
  border: 1px solid var(--border-border-primary, #d3d3d3);
`

const NumberContainerBox = styled(Box)`
  width: ${Spacings.m};
  height: ${Spacings.m};
  border-radius: 50%;
  background: var(--Purple, #353355);
`
function NumberContainer({ text }) {
  return (
    <NumberContainerBox align="center" justify="center">
      <FigmaTextWithStyle text={text} color="white" type="body2Bold" />
    </NumberContainerBox>
  )
}
