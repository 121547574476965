// https://www.fortnox.se/developer/authorization/get-authorization-code
// GET ?client_id={Client-ID}&redirect_uri=https%3A%2F%2Fmysite.org%2Factivation&scope=companyinformation&state=somestate123&access_type=offline&response_type=code&account_type=service

import { FORTNOX_CLIENT_ID } from '@consts/HikerConsts'

import RoutePath from '@contracts/enums/RoutePath'

import { addQueryParamsToUrl } from '@pure/libs/addQueryParamsToUrl'
import { createUniqueId } from '@pure/libs/Common'

export const OAUTH_STATE = createUniqueId()

export const FORTNOX_OAUTH_REDIRECT_URI = window.location.origin + RoutePath.FORTNOX_OAUTH_REDIRECT

export function getUrl() {
  return addQueryParamsToUrl('https://apps.fortnox.se/oauth-v1/auth', {
    client_id: FORTNOX_CLIENT_ID,
    scope: encodeURIComponent('companyinformation bookkeeping archive connectfile'), // https://www.fortnox.se/developer/guides-and-good-to-know/scopes
    access_type: 'offline',
    response_type: 'code',
    account_type: 'service',
    state: OAUTH_STATE
  })
}
