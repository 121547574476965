import invariant from 'invariant'

import { getActiveSessionForPlate } from './CloudFunctionsApiHandler'
import { getSite } from './DBApiHandler'
import { delay, HomeMachineState } from './HomeHelper'
import { createCheckoutSession } from './StripeHelper'

export const onClickPayWithStripe = (s: HomeMachineState) => {
  return Promise.resolve()
    .then(() => {
      const sessionId = s.data.session?.id
      invariant(sessionId, '!sessionid')
      return createCheckoutSession(s)
    })
    .then(({ url }) => url)
}

export type Services = {
  onClickPayWithStripe: typeof onClickPayWithStripe
  getSite: typeof getSite
  redirect: (url: string) => unknown
  getActiveSessionForPlate: typeof getActiveSessionForPlate
  delay: typeof delay
}

export const DefaultServices = {
  getSite,
  getActiveSessionForPlate,
  onClickPayWithStripe,
  redirect: (url: string) => (window.location.href = url),
  delay
}
