import invariant from 'invariant'

import RoutePath from '@contracts/enums/RoutePath'

import { getTotalCostForUnpayedSession } from '@pure/libs/PaymentHelper'
import { getHashedReceiptUrl } from '@pure/libs/ReceiptHelper'

import config from '@pay/Config'

import { createStripeCheckoutSession } from './CloudFunctionsApiHandler'
import { HomeMachineState } from './HomeHelper'

export const createCheckoutSession = ({ data }: HomeMachineState) => {
  const sessionId = data.session?.id?.toString()
  invariant(sessionId, 'sessionId is required')
  invariant(data.session, '!data.session')

  const price = getTotalCostForUnpayedSession(data.session)

  invariant(price > 0, 'price is 0, %s', price)
  const regNo = data.regNo

  return createStripeCheckoutSession({
    sessionId,
    successUrl: getHashedReceiptUrl({ sessionId, config, regNo, routePath: RoutePath.PAYMENT_CALLBACK }),
    cancelUrl: window.location.href
  }).then((res) => ({ url: res.redirectUrl }))
}
