import React from 'react'

import { Spacings } from '@contracts/enums/Spacings'

export type EmailBoxProps = {
  top?: boolean
  right?: boolean
  left?: boolean
  bottom?: boolean
  style?: React.CSSProperties
  children?: any
  spacing?: Spacings
  fullPadding?: boolean
  alignText?: 'center' | 'left' | 'right'
  width?: string
  direction?: 'row' | 'column'
  fullWidth?: boolean
  align?: 'center' | 'flex-start' | 'flex-end' | 'normal' | 'stretch'
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'
}

export const EmailBox = ({
  top,
  right,
  left,
  bottom,
  spacing,
  style,
  children,
  fullPadding,
  alignText,
  width,
  fullWidth
}: EmailBoxProps) => {
  return (
    <div
      style={{
        paddingTop: top || fullPadding ? spacing || Spacings.s : 0,
        paddingBottom: bottom || fullPadding ? spacing || Spacings.s : 0,
        paddingLeft: left || fullPadding ? spacing || Spacings.s : 0,
        paddingRight: right || fullPadding ? spacing || Spacings.s : 0,
        textAlign: alignText ? alignText : 'left',
        width: fullWidth ? '100%' : width || 0,
        ...style
      }}
    >
      {children}
    </div>
  )
}

// trigger
