import { Park } from '@contracts/types/Park'
import { Session } from '@contracts/types/Session'
import { Site } from '@contracts/types/Site'

import { SwishPaymentIntent, SwishPaymentIntentStatus } from '@pure/swagger/DrifterTypesDerived'

import { getIsMobile } from '@pay/hooks/useDeviceDetect'

export enum HomeSteps {
  LANDING,
  ENTER_REG_NO,
  UNPAYED_SESSIONS
}
const isMobile = typeof window !== 'undefined' ? getIsMobile(window) : false
export const DEFAULT_HOME_MACHINE_STATE = {
  data: { regNo: '', isMobile: isMobile },
  step: HomeSteps.LANDING
}

export type HomeMachineState = {
  data: {
    regNo?: string
    session?: Session | null
    phoneNumber?: string
    site?: Site
    swishPaymentIntent?: SwishPaymentIntent
    swishPaymentIntentStatus?: SwishPaymentIntentStatus
    isMobile: boolean
  }

  step: HomeSteps
}

export type HomeViewProps = {
  isLoading: boolean
  homeMachineState: HomeMachineState
  onPressContinue: (state: HomeMachineState) => Promise<unknown>
  onClickBack: () => unknown
  onClickHeaderLogo: () => unknown
}

export const delay = (ms: number = 1000) => new Promise((resolve) => setTimeout(resolve, ms))

export function toInternationalNumber(number) {
  // Remove any non-digit characters from the input number
  const cleanedNumber = number.replace(/\D/g, '')

  // Check if the number starts with '0' and replace it with '+46'
  if (cleanedNumber.startsWith('0')) {
    return '46' + cleanedNumber.slice(1)
  }

  // If the number doesn't start with '0', it might already be an international number
  // In that case, return the cleaned number as is
  return cleanedNumber
}

export const getSwishMessageForPark = ({ name = '' }: Park) => {
  return name.replace(/[^\w\såäöÅÄÖ]/gi, '')
}

export function getStepForCLickingHeaderLogo(homeMachineState: HomeMachineState) {
  return !homeMachineState.data.site ? HomeSteps.ENTER_REG_NO : HomeSteps.LANDING
}
