import dayjs from 'dayjs'
import invariant from 'invariant'

import { YYYY_MM_DD } from '@consts/DateConsts'

import { CameraEvent, PlaceCode, PlaceType, StrictCameraEvent } from '@contracts/types/CameraEvent'
import { DefaultSitePricing, DefaultSiteSessionLogic } from '@contracts/types/DefaultSite'
import { ParkingSession, ParkingSessionEvent } from '@contracts/types/ParkingSession'
import { SessionEventTypePrioritized, SessionEventTypePriorityMap } from '@contracts/types/ParkingSessionEventTypeInfo'
import { SessionEventName, SessionSite } from '@contracts/types/Session'
import { Site, SitePricing } from '@contracts/types/Site'
import { SiteSessionLogic } from '@contracts/types/SiteSessionLogic'
import { Vehicle } from '@contracts/types/Vehicle'

import { formatDateTime } from '@pure/libs/formatDateTime'
import { getSlotAndGateEventsForParkingSession } from '@pure/libs/getSlotAndGateEventsForParkingSession'
import { getSessionLogic } from '@pure/libs/SiteHelper'

import { CameraEventsContext } from './getContextForCameraEvents'
import { getCurrentGateDeviceId, getCurrentSlotPlaceCode } from './getParkingSessionEventsHelper'

export type VehiclesByPlate = {
  [plate: string]: Vehicle
}

export type ParkingSessionsClosedByPlate = {
  [plate: string]: ParkingSession[]
}

const INSIDE_OUTSIDE_TOLERANCE_MINUTES = 3
const GATE_TOLERANCE_MINUTES = 1

export const DEFAULT_WEIGHT = 1
export const UNKNOWN_PLACE_CODE = 'UNKNOWN'

const MIN_NUMBER_OF_GATE_EVENTS = 2
const SIMILIAR_CHARACTERS = { '0': 'O', O: '0', '1': 'I', I: '1', S: '5', '5': 'S', '2': 'Z', Z: '2', B: '8', '8': 'B' }

const SWEDISH_CAR_PLATE = /^[A-Z]{3}[0-9]{3}$/
const GERMAN_CAR_PLATE = /^[A-Z]{1,3}-[A-Z]{1,2}-\d{1,4}$/
const DANISH_CAR_PLATE = /^[A-Z]{2}\d{5}$/
const NORWEGIAN_CAR_PLATE = /^[A-Z]{2}\d{5}$/
export const PLATE_REGEXES = [SWEDISH_CAR_PLATE, GERMAN_CAR_PLATE, DANISH_CAR_PLATE, NORWEGIAN_CAR_PLATE]

export function getSimiliarPlates(plate: string): string[] {
  const splits = plate.split('')

  return splits.reduce((acc, char, index) => {
    if (SIMILIAR_CHARACTERS[char]) {
      const newPlate = [...splits]
      newPlate[index] = SIMILIAR_CHARACTERS[char]
      acc.push(newPlate.join(''))
    }

    return acc
  }, [] as string[])
}

// TODO WRITE TEST, should hash on plate and placecode
// TODO WRITE TEST, should be able to get back plate and placecode from key
export function getKeyForContextTable({ cam_code, place_code, plate_txt }: StrictCameraEvent) {
  return `${cam_code}_${place_code}_${plate_txt}`
}

export function getWeightForCameraEvent(gateEvent: CameraEvent, { cameraList = [] }: Site) {
  return cameraList.find((cam) => cam.deviceId === gateEvent.device_id.toString())?.weight || DEFAULT_WEIGHT
}

export function getKeyWithHighestValue(context: CameraEventsContext): string | undefined {
  return Object.entries(context).sort((a, b) => b[1].score - a[1].score)[0]?.[0]
}

export const findCameraEventWith = (
  { placeType, placeCodes }: { placeType?: PlaceType; placeCodes?: PlaceCode[] },
  cameraEvents: CameraEvent[]
): CameraEvent =>
  cameraEvents
    //
    .filter((c) => !placeType || placeType === c.place_type)
    .filter((c) => !placeCodes || placeCodes.includes(c.place_code as any))[0]

// TODO WRITE TEST, should return sessionLogic for placeCode or site sessionLogic or DefaultSiteSessionLogic
export const getSessionLogicForPlaceCode = (
  placeCode: string | undefined,
  { sessionLogic: siteSessionLogic, segments = [] }: Site
): SiteSessionLogic => {
  const segmentSessionLogic = segments.find((s) => !!s.slots.find((slot) => slot.placeCode === placeCode))?.sessionLogic
  return { ...DefaultSiteSessionLogic, ...siteSessionLogic, ...segmentSessionLogic }
}

export const getCurrentPricing = (parkingSession: ParkingSession, site: Site, now: string) => {
  const slotPlaceCode = getCurrentSlotPlaceCode(parkingSession, now)
  return getPricingForSlotPlaceCode(slotPlaceCode, parkingSession, site, now)
}

export const getPricingForSlotPlaceCode = (
  slotPlaceCode: string | undefined,
  parkingSession: ParkingSession,
  site: SessionSite,
  now: string
): SitePricing => {
  const { segments = [], pricing: _sitePricing } = site
  let segment = site.segments?.find((s) => !!s.slots?.find((slot) => slot.placeCode === slotPlaceCode))

  if (!segment) segment = getCurrentSegment(parkingSession, site, now)

  const segmentPricing = segment?.pricing
  const sitePricing = segments.length === 1 ? segments[0].pricing : _sitePricing

  const slot = segment?.slots?.find((s) => s.placeCode === slotPlaceCode)
  const slotPricing = slot?.pricing

  const pricing = { ...DefaultSitePricing, ...sitePricing, ...segmentPricing, ...slotPricing }

  return pricing
}

export const getCameraEventThatStartActiveSession = ({
  cameraEvents,
  lastSessionClosedAt,
  site
}: {
  cameraEvents: CameraEvent[]
  lastSessionClosedAt?: string
  site?: Site
}) => {
  const lastDate = dayjs(cameraEvents[cameraEvents.length - 1].record_time.toMillis())
    .utc()
    .format(YYYY_MM_DD)

  // DPM-1765 remove Gate:EXIT events if first in the camera list
  let eventsLength
  do {
    eventsLength = cameraEvents.length
    const firstEvent = cameraEvents[0]
    if (firstEvent && firstEvent.place_type == PlaceType.Gate && firstEvent.place_code == PlaceCode.Exit) {
      cameraEvents = cameraEvents.filter((e) => e !== firstEvent)
    }
  } while (eventsLength !== cameraEvents.length)

  const firstEventToday = cameraEvents.find((ce) => dayjs(getRecordTime(ce)).format(YYYY_MM_DD) === lastDate)
  const startNewSessionGapTimeSeconds = getSessionLogic({ site }).startNewSessionGapTimeSeconds

  if (
    lastSessionClosedAt &&
    cameraEvents.length === 1 &&
    dayjs(getRecordTime(cameraEvents[0])).isBefore(
      dayjs(lastSessionClosedAt).add(startNewSessionGapTimeSeconds, 'seconds') // Typically configured to 1 minute for gate and 3 min for gararage
    )
  )
    return undefined

  return (
    cameraEvents.reduce(
      (a, cameraEvent) => {
        const cameraDate = dayjs(cameraEvent.record_time.toMillis()).format(YYYY_MM_DD)

        // Only start sessions on the same day as cameraEvent
        if (lastDate !== cameraDate) return a

        // TODO WRITE TEST, should not start session if cameraEvent is before lastSessionClosedAt
        if (lastSessionClosedAt && dayjs(cameraEvent.record_time.toMillis()).isBefore(dayjs(lastSessionClosedAt)))
          return a

        if (cameraEvent.place_type !== PlaceType.Gate) return a

        if (PlaceCode.Entry === cameraEvent.place_code) return cameraEvent

        if (
          ([PlaceCode.Gate, PlaceCode.Inside, PlaceCode.Outside] as PlaceCode[]).includes(
            cameraEvent.place_code as PlaceCode
          ) &&
          !a
        ) {
          return cameraEvent
        }

        return a
      },
      undefined as CameraEvent | undefined
    ) || firstEventToday
  )
}

export const shouldEndSession = (activeSession: ParkingSession, site: Site): boolean => {
  invariant(activeSession, 'should not attempt to close session without active session')
  const cameraEvents = getSlotAndGateEventsForParkingSession(activeSession)
  const lastCameraEvent = cameraEvents[cameraEvents.length - 1]

  const internalGateDeviceIds =
    site?.segments
      ?.map((s) => s.gates)
      .flat()
      .filter((g) => g?.type === 'internal')
      .map((g) => g.deviceId) || []

  const gateEvents = cameraEvents
    .filter((c) => c.place_type === PlaceType.Gate)
    .filter((c) => !internalGateDeviceIds.includes(c.device_id.toString()))
    .filter((c, i, arr) => {
      if (i === 0) return true

      const cDate = dayjs(c.record_time.toMillis())
      const firstCameraEventDate = dayjs(arr[0].record_time.toMillis())

      return (
        cDate.diff(firstCameraEventDate, 'seconds') >=
        getSessionLogic({ parkingSession: activeSession }).startNewSessionGapTimeSeconds
      )
    })

  const lastGateEvent = gateEvents[gateEvents.length - 1]

  if (
    dayjs(getRecordTime(lastCameraEvent)).isSame(getRecordTime(lastGateEvent)) &&
    gateEvents.length >= MIN_NUMBER_OF_GATE_EVENTS &&
    ([PlaceCode.Gate, PlaceCode.Inside, PlaceCode.Outside, PlaceCode.Exit] as PlaceCode[]).includes(
      lastGateEvent.place_code as PlaceCode
    )
  )
    return true

  return false
}

export const getSlotEvents = (cameraEvents: CameraEvent[]): ParkingSession['slotEvents'] =>
  cameraEvents
    .filter((ce) => ce.place_type === PlaceType.Slot && ce.place_code)
    .reduce((a, ce) => ({ ...a, [getRecordTime(ce)]: ce }), {} as ParkingSession['slotEvents'])

export const getGateEvents = (cameraEvents: CameraEvent[]): ParkingSession['gateEvents'] =>
  cameraEvents
    .filter((ce) => ce.place_type === PlaceType.Gate && ce.place_code)
    .reduce((a, ce) => ({ ...a, [getRecordTime(ce)]: ce }), {} as ParkingSession['gateEvents'])

export function getCurrentSegment(p: ParkingSession, { segments = [] }: SessionSite, now: string) {
  const pc = getCurrentSlotPlaceCode(p, now)
  const gateDeviceId = getCurrentGateDeviceId(p, now)
  const segmentWithSlot = segments.find((s) => !!s.slots?.find((slot) => slot.placeCode === pc))
  const segmentWithGate = segments.find((s) => !!s.gates?.find((g) => g.deviceId === gateDeviceId))

  return segmentWithSlot || segmentWithGate
}

export function getRecordTime(cameraEvent?: CameraEvent): string {
  return formatDateTime(cameraEvent?.record_time?.toMillis?.())
}

export function getEventsForEndedSession(p: ParkingSession): ParkingSessionEvent[] {
  const { events } = p
  const lastEvent = events[events.length - 1]
  const createdAt = getRecordTime(p.lastCameraEvent)
  if (lastEvent && SessionEventTypePriorityMap[lastEvent.type as SessionEventTypePrioritized])
    lastEvent.endedAt = createdAt

  // TODO WRITE TEST, should not add PARKING_SESSION_ENDED if it already exists (BE-1324)
  if (events.some((e) => e.name === SessionEventName.PARKING_SESSION_ENDED)) return events

  return [
    ...events,
    {
      name: SessionEventName.PARKING_SESSION_ENDED,
      description: 'Parking ended',
      createdAt: createdAt
    } as ParkingSessionEvent
  ]
}

export const isSameEvent = (ce1?: CameraEvent, ce2?: CameraEvent) => {
  if (!ce1) return false
  if (!ce2) return false

  if (ce1.device_id !== ce2.device_id) return false
  if (ce1.place_type !== ce2.place_type) return false
  if (ce1.place_code !== ce2.place_code) return false

  return true
}
