import { GetParkingSessionPermitEventData } from '@contracts/types/GetParkingSessionData'
import { GetPermitResultForSession } from '@contracts/types/GetPermitResultForSession'

import { isValidPermitSlot } from '@pure/libs/permit/isValidPermitSlot'
import { isValidPermitTime } from '@pure/libs/permit/isValidPermitTime'

export const isValidPermit = (req: GetParkingSessionPermitEventData): GetPermitResultForSession => {
  let res: GetPermitResultForSession
  res = isValidPermitTime(req)
  if (!res.result) {
    logResult('isValidPermitTime', req, res)
    return res
  }

  /*
  res = isValidPermitActiveSessions(req)
  if (!res.result) {
    logResult('isValidPermitActiveSessions', req, res)
    return res
  }
    */

  res = isValidPermitSlot(req)
  if (!res.result) {
    logResult('isValidPermitSlot', req, res)
    return res
  }

  // TODO WRITE TEST, should return what permit is valid
  if (res.result) res.messages = [`Found valid permit: ${res.permit?.name} (${res.permit?.id})`]
  const finalResult = { ...res, result: true }
  logResult('final', req, finalResult)
  return finalResult
}

const logResult = (type: string, req: GetParkingSessionPermitEventData, res: GetPermitResultForSession) => {
  const plate = req.parkingSession.plate
  // lon only plates with known issues
  if (!['FTS78L', 'YJN006', 'YTF078', 'ECF936'].includes(plate)) return
  console.log(`isValidPermit for ${plate} result=${res.result} type=${type} fullResponse=${JSON.stringify(res)}`)
}
