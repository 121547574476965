import dayjs from 'dayjs'
import invariant from 'invariant'

import { DefaultSite } from '@contracts/types/DefaultSite'
import { Payment, Session } from '@contracts/types/Session'

import { INVOICE_EMAIL_FEE_SEK, INVOICE_EMAIL_FEE_SEK_INCL_VAT } from '@pure/libs/BillectaInvoiceFeeHelper'
import { formatDateTime } from '@pure/libs/formatDateTime'
import { getOnSessionClosedRequestFromSession } from '@pure/libs/getOnSessionClosedRequestFromSession'
import { getOffenseFees, getTotalOffenseFee } from '@pure/libs/PaymentHelperOffense'

import { isFalseSession } from './SessionHelper'

export const VAT_RATE = 0.25
export const CORE_ADMINISTRATION_FEE = 4.95 / (1 + VAT_RATE)
export const ADDITIONAL_ADMINISTRATION_FEE_RATE = 0.015

export const getTotalParkingPriceForSession = (session?: Session) => {
  if (!session) return 0

  return session?.parkingSession?.currentPayment?.fees.totalParkingFee || 0
}

export const getTotalCostForUnpayedSession = (session?: Session) => {
  if (!session) return 0
  return getPartialPayment(session).fees.totalFeeIncludedVAT
}

export const getPartialPaymentForSessions = (
  sessions: Session[],
  o: { administrationFee?: number; creditPayment?: boolean } = {}
): Payment =>
  sessions.reduce((payment: Payment, session) => {
    let partialPayment = getPartialPayment(session, o)

    if (o.creditPayment) {
      partialPayment = creditPayment(partialPayment)
    }

    if (!payment.fees) return partialPayment as Payment

    payment.fees = Object.entries(partialPayment.fees).reduce(
      (acc, [key, value]) => {
        if (typeof value !== 'number') return acc
        acc[key] = round((acc[key] || 0) + value)
        return acc
      },
      payment.fees as Payment['fees']
    )

    return payment
  }, {} as Payment)

export const getCollectiveInvoicePartialPayment = ({
  sessions,
  creditPayment
}: {
  sessions: Session[]
  creditPayment?: boolean
}): Payment => {
  return applyAdministrationFeeDeprecated({
    payment: getPartialPaymentForSessions(sessions, { administrationFee: 0, creditPayment }),
    creditPayment
  })
}

// use getPartialPayment instead
export function applyAdministrationFeeDeprecated({
  payment,
  creditPayment
}: {
  payment: Payment
  creditPayment?: boolean
}): Payment {
  const { totalParkingFee } = payment.fees

  const creditMultiplier = creditPayment ? -1 : 1

  const administrationFee = INVOICE_EMAIL_FEE_SEK * creditMultiplier
  const administrationFeeInclVAT = INVOICE_EMAIL_FEE_SEK_INCL_VAT * creditMultiplier

  const totalFee = totalParkingFee + administrationFee
  const totalParkingFeeIncludingVAT = round(getAmountInclVAT(totalParkingFee))
  const totalFeeIncludedVAT = round(totalParkingFeeIncludingVAT + administrationFeeInclVAT)

  return {
    ...payment,
    fees: {
      ...payment.fees,
      administrationFee,
      administrationFeeInclVAT,
      totalFee,
      totalFeeIncludedVAT
    }
  }
}

export function getPartialPayment(
  session: Session,
  o: { administrationFee?: number; now?: string } = { now: dayjs().format() }
): Payment {
  session.request = session.request || getOnSessionClosedRequestFromSession(session)

  // const guestPermit = session.parkingSession.events.find((e) => e.permit.type === 'guest')

  const parkingFee = isFalseSession(session) ? 0 : session.request?.fees?.parkingFee || 0
  const totalParkingFee = round(getTotalParkingFee(session))
  const _administrationFee = totalParkingFee > 0 ? getAdminFeeExclVAT(totalParkingFee) : 0
  const administrationFee = round(o.administrationFee || _administrationFee)

  const totalFee = totalParkingFee + administrationFee

  const totalParkingFeeIncludingVAT = round(getAmountInclVAT(totalParkingFee))
  const administrationFeeInclVAT = round(getAmountInclVAT(administrationFee))

  const totalFeeIncludedVAT = round(totalParkingFeeIncludingVAT + administrationFeeInclVAT)

  // TODO, implement next iteration of receipt handling see https://drifterworld.atlassian.net/browse/BE-686?focusedCommentId=12467

  return {
    currencyCode: 'SEK',
    vatPercent: VAT_RATE * 100,
    createdAt: formatDateTime(o.now),
    fees: {
      parkingFee,
      totalParkingFee,
      administrationFee,
      totalParkingFeeIncludingVAT,
      administrationFeeInclVAT,
      totalVAT: round(totalFee * VAT_RATE),
      totalFee,
      totalFeeIncludedVAT,
      offenseFee: round(getTotalOffenseFee(session)),
      offenseFees: getOffenseFees(session)
    }
  } as Payment
}

export function creditPayment(payment: Payment): Payment {
  const fees = payment.fees
  const creditedPayment = {
    ...payment,
    fees: {
      parkingFee: (fees.parkingFee || 0) * -1,
      totalParkingFee: fees.totalParkingFee * -1,
      administrationFee: fees.administrationFee * -1,
      totalParkingFeeIncludingVAT: fees.totalParkingFeeIncludingVAT * -1,
      administrationFeeInclVAT: fees.administrationFeeInclVAT * -1,
      totalVAT: fees.totalVAT * -1,
      totalFee: fees.totalFee * -1,
      totalFeeIncludedVAT: fees.totalFeeIncludedVAT * -1,
      offenseFee: (fees.offenseFee || 0) * -1,
      offenseFees: fees.offenseFees
    }
  }

  return creditedPayment
}

export function getTotalParkingFee(session: Session) {
  const { request, site = DefaultSite } = session
  invariant(request, '!request')

  if (isFalseSession(session)) return 0

  const { chargingFee = 0 } = request?.fees || {}
  let { parkingFee = 0 } = request?.fees || {}

  const offenseFee = getTotalOffenseFee(session) || 0

  // TODO WRITE TEST, should only cap parking fee and not offese fees
  if (site.maxTotalParkingFee && parkingFee > site.maxTotalParkingFee) parkingFee = site.maxTotalParkingFee

  const totalParkingFee = Number(parkingFee) + Number(offenseFee) + Number(chargingFee)

  return totalParkingFee
}

export function getAdminFeeExclVAT(parkingPrice: number): number {
  if (parkingPrice < 0) parkingPrice = 0
  // Calculate the additional administration fee (excl. VAT)
  const additionalAdministrationFeeExclVAT = parkingPrice * ADDITIONAL_ADMINISTRATION_FEE_RATE

  // Calculate the additional administration fee (incl. VAT)
  return additionalAdministrationFeeExclVAT + CORE_ADMINISTRATION_FEE
}

export const getAmountInclVAT = (amount: number): number => amount * (1 + VAT_RATE)

export const round = (num: number) => Math.round(num * 100) / 100

//
