import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useState } from 'react'

import { YYYY_MM_DD } from '@consts/DateConsts'

import { Spacings } from '@contracts/enums/Spacings'
import { ParkingSessionEventOffense } from '@contracts/types/ParkingSession'
import { Session, SessionEventName } from '@contracts/types/Session'

import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { DayJS } from '@pure/libs/DayJsHelper'
import { getPartialPayment } from '@pure/libs/PaymentHelper'
import { formatPriceForUnpayedSession as formatPrice } from '@pure/libs/ReceiptHelper'

import { getActiveSessionHeadingElements, getOffenseInformation } from '@pay/helpers/ActiveSessionHelper'
import { useParkingForParkId } from '@pay/hooks/useParking'

import { ActiveSessionWidgetRowPrice } from './ActiveSessionWidgetRow'
import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { StationInfoDrawer } from './StationInfoDrawer'
import { GreyLine } from './StyledComponents'

const ActiveSessionWidget: React.FC<{ session: Session }> = ({ session }) => {
  const parkId = parseInt(session.parkingSession?.siteId || '')
  const { data: parking } = useParkingForParkId(parkId)

  const [showDetails, setShowDetails] = useState(false)
  const [showParkDetails, setShowParkDetails] = useState(false)

  const hours = DayJS().diff(DayJS(session.parkingSession?.startedAt), 'h')
  const minutes = DayJS().diff(DayJS(session.parkingSession?.startedAt), 'm') - hours * 60
  const seconds = DayJS().diff(DayJS(session.parkingSession?.startedAt), 's') - hours * 3600 - minutes * 60

  const parkingTimeDiffText = DayJS.duration({ hours, minutes, seconds }).format('HH:mm:ss')

  const payment = getPartialPayment(session)
  const totalCost = payment.fees?.totalFeeIncludedVAT || 0

  const chargingCost = 0 // session.costs?.total?.cost || 0
  const isCharging = chargingCost > 0
  const adminFee = session.parkingSession?.currentPayment?.fees?.administrationFee || 0

  const { activeSessionTypeTextKey, activeSessionTypeIcon, infoIcon } = getActiveSessionHeadingElements(
    false,
    !!session?.parkingSession?.events?.find((event) => event.name === SessionEventName.PARKING_OFFENSE)
  )

  const offenseEvent = session?.parkingSession?.events?.find(
    (event) => event.name === SessionEventName.PARKING_OFFENSE
  ) as ParkingSessionEventOffense

  const { isOffense, headingTextKey, descriptionTextKey } = getOffenseInformation(offenseEvent?.offenseType)

  const showOffense = isOffense && !!headingTextKey && !!descriptionTextKey

  return (
    <Container
      fullWidth
      fullPadding
      spacing={Spacings.s}
      isOffense={!!session?.parkingSession?.events?.find((event) => event.name === SessionEventName.PARKING_OFFENSE)}
    >
      <StationInfoDrawer site={session?.site} open={showParkDetails} onClose={() => setShowParkDetails(false)} />
      <Box fullWidth direction="row" align="center" justify="space-between" bottom spacing={Spacings.s}>
        <Box direction="row" align="center">
          <FigmaImage imageKey={activeSessionTypeIcon} />
          <Box left spacing={Spacings.s}>
            <FigmaText textKey={activeSessionTypeTextKey} text={session?.site?.name} />
          </Box>
        </Box>
        <Box onClick={() => setShowParkDetails(true)} pointer>
          <FigmaImage imageKey={infoIcon} />
        </Box>
      </Box>

      {showOffense ? (
        <Box fullWidth bottom spacing={Spacings.m} style={{ paddingTop: Spacings.xs }}>
          <FigmaText textKey={headingTextKey} />
          <Box fullWidth top spacing={Spacings.xxs}>
            <FigmaText textKey={descriptionTextKey} />
          </Box>
        </Box>
      ) : null}

      {showDetails && (
        <Box fullWidth>
          <Box fullWidth direction="row" justify="space-between">
            <FigmaText
              textKey={Texts.textActiveSessionParkingInformationPaymentInformationCar}
              text={session.parkingSession?.plate}
            />
            <FigmaText
              textKey={Texts.textActiveSessionParkingInformationPaymentInformationCar}
              text={dayjs(session.parkingSession?.startedAt).format(YYYY_MM_DD)}
            />
          </Box>
          <Box top spacing={Spacings.xs}>
            <FigmaText
              textKey={Texts.textActiveSessionParkingInformationPaymentInformationAdress}
              text={parking?.data?.data?.name}
            />
          </Box>
          <Box top bottom spacing={Spacings.s} fullWidth>
            <GreyLine />
          </Box>
        </Box>
      )}

      <Box fullWidth>
        <ActiveSessionWidgetRowPrice
          textKey={Texts.textActiveSessionActiveSessionActiveSessionTimeTotal}
          icon={Images.timeIcon}
          rightValue={parkingTimeDiffText}
        />
      </Box>

      {showDetails && (
        <Box fullWidth>
          <Box fullWidth direction="row" justify="space-between" top spacing={Spacings.xs}>
            <FigmaTextWithStyle
              type="body2Itallic"
              textKey={Texts.textActiveSessionActiveSessionActiveSessionTimeParking}
              color="gray600"
            />
            <Box right spacing={Spacings.xxs}>
              <FigmaTextWithStyle type="body2Itallic" text={parkingTimeDiffText} color="gray600" />
            </Box>
          </Box>

          <Box top bottom spacing={Spacings.s} fullWidth>
            <GreyLine />
          </Box>
        </Box>
      )}

      {isCharging && (
        <Box top spacing={Spacings.xs} fullWidth>
          <ActiveSessionWidgetRowPrice
            textKey={Texts.textActiveSessionActiveSessionActiveSessionCostChargingAmount}
            icon={Images.chargingIcon}
            rightValue={formatPrice(chargingCost)}
          />
        </Box>
      )}

      <Box top={!showDetails} spacing={Spacings.xs} fullWidth>
        <ActiveSessionWidgetRowPrice
          textKey={Texts.textActiveSessionActiveSessionActiveSessionCostTotal}
          icon={Images.costIcon}
          rightValue={`${formatPrice(totalCost)}`}
        />
      </Box>

      {showDetails && (
        <Box top spacing={Spacings.xs} fullWidth>
          <Box fullWidth direction="row" align="center" justify="space-between">
            <FigmaTextWithStyle
              textKey={Texts.textActiveSessionActiveSessionActiveSessionAdministrationFee}
              type="body2Itallic"
              // color="gray600"
            />
            <FigmaTextWithStyle text={formatPrice(adminFee || 0)} type="body2Itallic" color="gray600" />
          </Box>
          <Box top bottom spacing={Spacings.s} fullWidth>
            <GreyLine />
          </Box>
        </Box>
      )}

      <Box onClick={() => setShowDetails(!showDetails)} fullWidth alignText="center" link top spacing={Spacings.s}>
        <FigmaText
          textKey={
            !showDetails
              ? Texts.textActiveSessionActiveSessionActiveSessionShowDetails
              : Texts.textActiveSessionActiveSessionActiveSessionHideDetails
          }
        />
      </Box>
    </Container>
  )
}

export default ActiveSessionWidget

export const Container = styled(Box)<{ isOffense?: boolean }>`
  border-radius: 0.625rem;
  border: ${({ isOffense }) =>
    isOffense ? `2px solid var(--color-brand-main, #ff0000)` : `2px solid var(--color-brand-main, #b11d94)`};
  background: #fff;
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.1);
`
