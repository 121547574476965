const env: string | undefined = (import.meta as any).env?.VITE_ENVIRONMENT

export const defaultConfig = {
  apiUrl: 'https://app.drifterworld.se',
  sentryDsn: 'https://075d13feb58a7a5d206b416db3ef9fa4@o4504077826981888.ingest.us.sentry.io/4506110342856704',
  payBaseUrl: 'https://pay.drifterworld.se',
  HASH_KEY: 'drifterworldabv1',
  depreceatedStripePriceId: 'price_1O78RHLxoUxL65YUNcB6kPed',
  enableSentry: false,
  enableLogFirestoreQueryResult: false,
  enableSandbox: false,
  enableSlack: false,
  disableHashReceiptCheck: false,
  enableShowFreeSessions: false,
  enableExperimentalLogger: true,
  enableAdmin: false,
  enableProfile: false
}

export type ConfigType = typeof defaultConfig

export const local: ConfigType = {
  ...defaultConfig,
  enableSentry: false,
  // apiUrl: 'https://apptest.drifterworld.se',
  payBaseUrl: typeof window !== 'undefined' ? window.location.origin : 'http://localhost:5173',
  disableHashReceiptCheck: true,
  enableShowFreeSessions: true,
  enableSlack: false
}

export const prod: ConfigType = {
  ...defaultConfig,
  enableSentry: true,
  enableSlack: true
}

export const config =
  {
    local,
    prod
  }[env || 'prod'] || prod

// config = { ...config, ...getFeatureFlagsFromUrl() } //

export default config
