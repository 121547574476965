import { doc } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { Session } from '@contracts/types/Session'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@pay/libs/FirebaseOptions'

// Method is called hiker session because only hiker sessions are allowed to be read from according to firestore rules
export const useHikerSession = (id: string) =>
  useFirestoreQuery<Session>(doc(db, Collections.SESSIONS, id), {
    fnName: 'useHikerSession',
    enabled: !!id
  })
