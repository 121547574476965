import { doc, getDoc } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { Site } from '@contracts/types/Site'

import { DB } from '@pure/emu/types'
import { createUniqueId } from '@pure/libs/Common'
import { getDocData } from '@pure/libs/CommonDBApiHandlerHelper'

export const getSite = (siteId: string, db: DB): Promise<Site | undefined> =>
  getDoc(doc(db, Collections.SITES, siteId || createUniqueId())).then(getDocData)
