import dayjs from 'dayjs'
import useQueryParams, { ReceiptQueryParams } from 'pure/libs/useQueryParams'

import { ClientReceiptPayload, decryptClientReceiptPayload } from '@pure/libs/ReceiptHelper'

import { useHikerSession } from '@pay/hooks/useHikerSession'

import Box from './Box'
import { LoadingPage } from './LoadingPage'
import { Receipt } from './Receipt'
import { ReceiptButtons } from './ReceiptButtons'

// Used by the mobile app
export const ReceiptDetailPage = () => {
  const { hash = '' } = useQueryParams<ReceiptQueryParams>()
  const { sessionId }: any = decryptClientReceiptPayload(hash)
  const { data: session, isLoading: isLoadingSession } = useHikerSession(sessionId)

  const clientReceiptPayload: ClientReceiptPayload = { sessionId, createdAt: dayjs().format() }

  if (!session) return null
  if (isLoadingSession) return <LoadingPage LayoutComponent={Box} />

  return (
    <Receipt
      clientReceiptPayload={clientReceiptPayload}
      LayoutComponent={Layout}
      enableSupportFooter={false}
      enableSuccessMessage={false}
      session={session}
      buttonElement={<ReceiptButtons session={session} />}
    />
  )
}

const Layout = ({ children }) => (
  <Box fullWidth align="center">
    {children}
  </Box>
)
