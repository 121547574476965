import styled from '@emotion/styled'

import { Spacings } from '@contracts/enums/Spacings'

import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'

import { HomeViewProps } from '@pay/libs/HomeHelper'

import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { Layout } from './Layout'

export const HomeEnterRegNoEmptyState = (props: HomeViewProps) => {
  return (
    <Layout onClickHeaderLogo={props.onClickHeaderLogo}>
      <Box fullWidth fullPadding align="center" spacing={Spacings.xl}>
        <Container fullPadding>
          <FigmaImageContainer imageKey={Images.thumbsUp} />
        </Container>
        <Box top>
          <FigmaText textKey={Texts.textPaymentsQrCodePaymentEmptyStateText1} />
        </Box>
        <Box top>
          <FigmaText textKey={Texts.textPaymentsQrCodePaymentEmptyStateText2} />
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  border-radius: 12.5rem;
  background: var(--color-brand-secondary, #353355);
`
