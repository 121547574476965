import PublicAssets from '@contracts/enums/PublicAssets'

import { ImageKey } from '@pure/emu/Antiloop'
import { getUrlToPublicAsset } from '@pure/libs/EmailComponentHelper'

import { config } from './Config'

export const EmailImage = ({
  imageKey,
  publicAsset,
  style
}: {
  imageKey: ImageKey
  publicAsset: PublicAssets
  style?: any
}) => (
  <img
    src={getUrlToPublicAsset(publicAsset, config)}
    alt={imageKey.name}
    width={`${imageKey.width}px`}
    height={`${imageKey.height}px`}
    style={style}
  />
)
