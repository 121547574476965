import invariant from 'invariant'

type Collection = FirebaseFirestore.QuerySnapshot<FirebaseFirestore.DocumentData>

// Get array of doc data from collection
export const getCollectionData = (collection: Collection) => collection.docs.map(getDocData)

export const getFirstDocData = (collection, errorMessage: string) => getDocData(getFirstDoc(collection, errorMessage))

export const getFirstDoc = (collection: Collection, errorMessage: string) => {
  invariant(collection.docs.length <= 1, errorMessage)
  return collection.docs[0]
}

export function getDocData(doc) {
  if (!doc) return undefined
  if (!doc.exists) return undefined
  const data = { id: doc.id, ...doc.data() }
  return data
}

export const assertNotMultipleDocs = <T>(
  docs: Array<T>,
  errorMessage: string = 'Found Multiple documents'
): T | undefined => {
  invariant(docs.length <= 1, errorMessage)
  return docs[0]
}
