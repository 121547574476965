import 'whatwg-fetch'

import { Fetcher } from 'openapi-typescript-fetch'

import { paths } from '@pure/swagger/DrifterTypes'

import { ApiHandlerFetchClientLogger } from './ApiHandlerFetchClientLogger'
import { getLanguageCode } from './useLanguageDeprecated'

// Declare fetcher for paths.
export const publicFetcher = Fetcher.for<paths>()

const language = getLanguageCode()
const baseUrl = 'https://app.drifterworld.se'

const fetcherConfig = {
  baseUrl,
  init: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      locale: language,
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  use: [ApiHandlerFetchClientLogger]
}

publicFetcher.configure(fetcherConfig)

export const authenticatedFetcher = async (auth, osName: string, appVersion: string) => {
  const idToken = await auth?.currentUser?.getIdToken(true)

  const fetcher = Fetcher.for<paths>()
  fetcher.configure({
    ...fetcherConfig,
    init: {
      ...fetcherConfig.init,
      headers: {
        ...fetcherConfig.init.headers,
        Authorization: `Bearer ${idToken}`,
        DrifterAppPlatform: osName,
        DrifterAppVersion: appVersion
      }
    }
  })

  return fetcher
}
