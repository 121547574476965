import { doc } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { Site } from '@contracts/types/Site'

import { createUniqueId } from '@pure/libs/Common'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'
import { getParkIdFromUrl } from '@web-js/libs/getParkIdFromUrl'

import { db } from '@pay/libs/FirebaseOptions'

export const useSiteForWindow = (window) => {
  const parkId = getParkIdFromUrl(window.location.href)?.toString()
  return useSite(parkId || '')
}

export const useSite = (id: string) =>
  useFirestoreQuery<Site>(doc(db, Collections.SITES, id || createUniqueId()), { fnName: 'useSite', enabled: !!id })
