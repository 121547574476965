import { useId } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { Spacings } from '@contracts/enums/Spacings'

import Texts from '@pure/assets/PayTexts'
import { getFigmaText, getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import { useForm } from '@pay/hooks/useForm'
import { HomeViewProps } from '@pay/libs/HomeHelper'
import { RequiredTextValidation } from '@pay/libs/ValidationHelper'
import { Variables } from '@pay/libs/Variables'

import Box from './Box'
import Button from './Button'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { HomeEnterRegNoEmptyState } from './HomeEnterRegNoEmptyState'
import { HomeLandingInfo } from './HomeLandingInfo'
import { Layout } from './Layout'
import { RegistrationNumberField } from './RegistrationNumberField'

const ValidationSchema = Yup.object()
  .shape({
    regNo: RequiredTextValidation('Enter reg no')
  })
  .required()

export const HomeEnterRegNo = (props: HomeViewProps) => {
  const { homeMachineState } = props

  const formProps = useForm(ValidationSchema, { defaultValues: { regNo: props.homeMachineState.data.regNo || '' } })
  const inputId = useId()

  const onSubmit = formProps.handleSubmit(({ regNo }) => {
    const trimmedRegNo = regNo.trim()
    return props.onPressContinue({ ...homeMachineState, data: { ...homeMachineState.data, regNo: trimmedRegNo } })
  })

  if (homeMachineState.data.session === null) return <HomeEnterRegNoEmptyState {...props} />

  return (
    <Layout onClickHeaderLogo={props.onClickHeaderLogo}>
      <Box fullWidth fullPadding>
        <form onSubmit={onSubmit}>
          <label htmlFor={inputId}>
            <FigmaTextWithStyle
              type="captionBold"
              text={getFigmaText(Texts.textPaymentsQrCodePaymentEnterRegNo)}
              color="neutral7"
            />
          </label>
          <Box top spacing={Spacings.m} fullWidth>
            <Controller
              control={formProps.control}
              name="regNo"
              render={({ field, fieldState }) => (
                <RegistrationNumberField
                  {...field}
                  id={inputId}
                  fullWidth
                  fieldState={fieldState}
                  autoFocus
                  placeholderTextKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
                />
              )}
            />
          </Box>
          <Box top fullWidth>
            <Button
              type="submit"
              fullWidth
              loading={props.isLoading}
              text={getFigmaTextFromVariable(Variables['VariableID:734:11919'])}
            />
          </Box>
        </form>

        <Box top spacing={Spacings.m} align="center" fullWidth>
          <HomeLandingInfo />
        </Box>
      </Box>
    </Layout>
  )
}
