import { Session } from '@contracts/types/Session'

import { DayJS } from '@pure/libs/DayJsHelper'
import { getPricingForSlotPlaceCode } from '@pure/libs/getParkingSessionHelper'

import { getFreeDurationMinutesForPricing } from './getFreeDurationMinutesForPricing'

// How many free minutes that have been used for a session
export function getFreeDurationMinutesForSession(session: Session | undefined) {
  return session?.parkingSession?.events
    ?.filter((event) => event.type === 'free')
    .reduce((acc, event) => {
      if (!event.endedAt) return acc
      return acc + DayJS(event.endedAt).diff(event.startedAt, 'minutes')
    }, 0)
}

// How many free minutes that is allowed for a session
export function getMaxAllowedFreeDurationForSession(session: Session | undefined) {
  const placeCode = session?.parkingSession?.events?.find((event) => event.type === 'free')?.place_code

  if (!session?.parkingSession) return 0
  if (!session?.site) return 0

  const pricing = getPricingForSlotPlaceCode(placeCode, session?.parkingSession, session?.site, DayJS().format())
  const freeDurationMinutes = getFreeDurationMinutesForPricing(pricing)
  return freeDurationMinutes
}
