import type FirebaseFunctions from 'firebase/functions'
import { httpsCallable } from 'firebase/functions'

import { CloudFunctions } from '@contracts/types/CloudFunctionTypes'

import { captureException } from './SentryHelper'

export const callFunction = ({
  cloudfunction,
  data,
  functions
}: {
  cloudfunction: CloudFunctions
  data?: any
  functions: FirebaseFunctions.Functions
}) =>
  httpsCallable(
    functions,
    cloudfunction
  )(data)
    .then(({ data }) => Promise.resolve(console.log('Success', cloudfunction)).then(() => data))
    .catch((e) => {
      captureException(e, (scope) => {
        scope.addBreadcrumb({ message: `${cloudfunction} request:`, data: { data } })
        scope.setTransactionName(cloudfunction)
        scope.setFingerprint([cloudfunction])
        return scope
      })

      return Promise.reject(e)
    })
