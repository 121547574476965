// The webhooks below can be configured here: https://api.slack.com/apps/A0696JVU5RP/incoming-webhooks?success=1

export enum SlackWebhooks {
  OPS_APP_ERRORS = 'https://hooks.slack.com/services/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',
  OPS_API_ERRORS = 'https://hooks.slack.com/services/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',
  OPS_HIKER_PAY_QR = 'https://hooks.slack.com/services/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',
  OPS_HIKER_DEBUG = 'https://hooks.slack.com/services/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',
  OPS_HIKER_GUEST_PERMIT_ADMIN_ERRORS = 'https://hooks.slack.com/services/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',
  OPS_MY_DRIFTER = 'https://hooks.slack.com/services/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'
}
