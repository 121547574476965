import invariant from 'invariant'

import { DefaultSite } from '@contracts/types/DefaultSite'
import { OffenseTypeItem } from '@contracts/types/OffenseType'
import { OffenseType } from '@contracts/types/OffenseType'
import { Session } from '@contracts/types/Session'

import { OffenseTypes } from '@pure/libs/OffenseHelper'

export const SOMETHING_HIGH = 100000000

export const getTotalOffenseFee = (session: Session): number =>
  Object.values(getOffenseFees(session)).reduce((acc, fee) => acc + fee, 0)

export const getOffenseFees = (session: Session): { [offenseType: string]: number } => {
  return Object.values(OffenseType).reduce((acc, offense_type: OffenseType) => {
    const fee = getOffenseFeeForOffenseType({ offense_type, session })
    if (fee > 0) acc[offense_type] = fee
    return acc
  }, {})
}

export const getOffenseFeeForOffenseType = ({
  offense_type,
  session
}: {
  session: Session
  offense_type: OffenseType
}) => {
  const { site = DefaultSite, request } = session

  // TODO WRITE TEST, offense fee for sessions without request is 0
  if (!request) return 0

  const { childSessions = [] } = request
  const offenseTypeItem: OffenseTypeItem = OffenseTypes[offense_type]

  invariant(offenseTypeItem, '!offenseType for offense_type %s', offense_type)

  const sum = childSessions
    //
    .filter((c) => !!offense_type && c.offense_type === offense_type)
    .reduce((acc, { costs }) => acc + costs, 0)

  // is the offense type enabled on the site?
  if (!site?.[offenseTypeItem.featureKey]) return 0

  // is the offense maxAmount configured correctly ? if no then return the fee
  if (!site[offenseTypeItem.maxAmountKey]) return sum

  return Number(Math.min(site[offenseTypeItem.maxAmountKey] || SOMETHING_HIGH, sum))
}
