import { ReactElement } from 'react'

import { Language, TextKey } from '@pure/emu/Antiloop'

import { getFigmaText } from '@web-components/shared/TextRepository'

export type FigmaTextProps = {
  textKey?: TextKey
  styleTextKey?: TextKey
  className?: string
  text?: string
  onClick?: () => unknown
  link?: boolean
  anchorHref?: string
  textTransform?: 'uppercase'
  separator?: ReactElement
  lineHeight?: number
  language?: Language
}

const FigmaText = (props: FigmaTextProps) => {
  const { text: _text, styleTextKey, textKey, className, link, anchorHref, textTransform, language } = props
  let { style } = styleTextKey || textKey || {}

  if (!style) return null
  style = mapStyle(style, link)

  let text = typeof _text === 'string' ? _text : (textKey && getFigmaText(textKey, language)) || ''
  if (textTransform === 'uppercase') text = text.toUpperCase()
  if (anchorHref)
    return (
      <a href={anchorHref} style={style} dangerouslySetInnerHTML={{ __html: text }} target="_blank" rel="noreferrer" />
    )

  return <span dangerouslySetInnerHTML={{ __html: text }} className={className} style={style} />
}

const mapStyle = (style, link) => {
  if (link) return { ...style, textDecoration: 'underline', pointer: 'cursor' }
  return style
}

export default FigmaText
