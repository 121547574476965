import { Language } from '@pure/emu/Antiloop'

export const A_COLOR_THAT_NEEDS_TO_BE_IN_FIGMA = 'rgba(84, 85, 172, 0.08)'
export const FIGMA_MODES_LANG_DEPRECATED = {
  swe: '19:0',
  eng: '26:1'
}

export const FIGMA_MODES_LANG: {
  [property in Language]: string
} = {
  sv: '19:0',
  en: '26:1'
}
