import { toast } from 'react-toastify'

import { BorderRadiuses, BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'
import { TextKey } from '@pure/emu/Antiloop'

import { FigmaTextWithStyle } from '@pay/components/FigmaTextWithStyle'

export function success({ textKey, text }: { textKey: TextKey; text?: string }) {
  toast(<FigmaTextWithStyle type="body1Regular" textKey={textKey} text={text} color="black" />, {
    type: 'success',
    position: 'top-center',
    style: {
      borderRadius: `var(--borderRadius-m, ${BorderRadiusesPx.lightlyRounded})`,
      border: `1px solid var(--toast-toast-success, ${Colors.baseDarkGreen})`,
      background: ' #eefff9',
      boxShadow: `0px 2px 17px 0px rgba(0, 0, 0, 0.1)`
    }
  })
}

export function error({ textKey }: { textKey: TextKey }) {
  toast(<FigmaTextWithStyle type="body1Regular" textKey={textKey} color="black" />, {
    type: 'error',
    position: 'top-center',
    style: {
      borderRadius: `${BorderRadiuses.lightlyRounded}`,
      border: `1px solid ${Colors.red7}`,
      background: `var(--surface-surface-error, ${Colors.red1})`,
      boxShadow: `0px 2px 17px 0px rgba(0, 0, 0, 0.1)`
    }
  })
}
