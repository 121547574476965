import { initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import { FIREBASE_CONFIG, REGION } from '@consts/HikerConsts'

import { asSingleton } from '@pure/libs/asSingleton'

const app = initializeApp(FIREBASE_CONFIG)

const functions = getFunctions(app, REGION)

const db = asSingleton(() => initializeFirestore(app, { ignoreUndefinedProperties: true }))

export { functions, db }
