import React, { useContext } from 'react'

import { Session } from '@contracts/types/Session'
import { User } from '@contracts/types/User'

import { StorageKey } from './useLocalStorageItemHelper'
import { useStorageItem } from './useStorageItem'

export type State = {
  user: User
  firebaseUser?: any
  token: string
  autoStartToken?: string
  receipt?: Session
  temporaryToken?: string
}

export const DEFAULT_STATE: State = {
  user: { id: '', email: undefined, language: 'sv' } as any,
  token: ''
}

export type AppContext = {
  state: State
  setState: (state: State) => unknown
}
export const Context = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof State> = []

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS
  })

  return { state, setState }
}

export default function useAppState() {
  return useContext(Context)
}

export const useUserId = () => useAppState().state.user.id
