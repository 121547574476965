import { SitePricing, SitePricingConfigurationRelative } from '@contracts/types/Site'

export function getFreeDurationMinutesForPricing(pricing?: SitePricing) {
  if (!pricing) return 0
  if (pricing.parking.type === 'relative')
    return (pricing.parking as SitePricingConfigurationRelative).freeDurationMinutes

  if (pricing.parking.type === 'absolute')
    return pricing.parking?.items?.find((item) => item.freeDurationMinutes)?.freeDurationMinutes

  return 0
}
