import React, { useState } from 'react'

import TextFieldForm, { TextFieldFormProps } from './TextFieldForm'

const DISALLOWED_CHARACTERS_REGEX = /[-.]/g

export const RegistrationNumberField = React.forwardRef<HTMLInputElement, TextFieldFormProps & { value?: string }>(
  (props, ref) => {
    const [value, setValue] = useState<string>(props.value || '')
    const [lastKeyPressed, setLastKeyPressed] = useState<string>('')

    return (
      <TextFieldForm
        {...props}
        value={value.toUpperCase()}
        ref={ref}
        onChange={(e) => {
          // Fix for Apple devices automatically adding a period after a double space
          const replacedValue = lastKeyPressed === ' ' ? ' ' : ''
          const newValue = e.target.value.replace(DISALLOWED_CHARACTERS_REGEX, replacedValue)

          setValue(newValue)

          if (!props.onChange) {
            return
          }

          props.onChange({
            ...e,
            target: {
              ...e.target,
              value: newValue
            }
          })
        }}
        onKeyDown={(e) => {
          setLastKeyPressed(e.key)

          if (!props.onKeyDown) {
            return
          }

          props.onKeyDown(e)
        }}
      />
    )
  }
)

RegistrationNumberField.displayName = 'RegistrationNumberField'
