import styled from '@emotion/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import RoutePath from '@contracts/enums/RoutePath'
import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'
import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Session } from '@contracts/types/Session'

import { Colors } from '@pure/assets/Colors'
import Texts from '@pure/assets/PayTexts'
import { ClientReceiptPayload, formatPrice } from '@pure/libs/ReceiptHelper'

import { slack } from '@web-js/libs/SlackHelperClient'

import { useIsDesktop } from '@pay/hooks/useIsMobile'

import { EmailReceipt } from '@web-components/components/receipt-email/EmailReceipt'

import Box from './Box'
import FigmaText from './FigmaText'
import { Layout, LayoutProps } from './Layout'
import { LoadingPage } from './LoadingPage'
import { MessageSuccess } from './MessageSuccess'

export const Receipt = ({
  clientReceiptPayload,
  LayoutComponent = Layout,
  enableSupportFooter = true,
  enableSuccessMessage = true,
  session,
  buttonElement: ButtonElement
}: {
  clientReceiptPayload: ClientReceiptPayload
  LayoutComponent?: React.FC<LayoutProps>
  enableSupportFooter?: boolean
  enableSuccessMessage?: boolean
  session: Session
  buttonElement?: React.ReactElement
}) => {
  const isDesktop = useIsDesktop()

  let { sessionId } = clientReceiptPayload

  if (typeof sessionId === 'number') sessionId = (sessionId as any).toString()

  const navigate = useNavigate()

  if (!session) return <LoadingPage />

  if (session?.payment?.fees?.totalFeeIncludedVAT === 0) {
    slack(
      `Generated a receipt with price - 0
      sessionId: ${session?.id}
      receiptUrl: ${window.location.href}`,
      SlackWebhooks.OPS_HIKER_PAY_QR
    )
  }

  return (
    <LayoutComponent onClickHeaderLogo={() => navigate(RoutePath.HOME)}>
      <Box fullWidth align="center" bottom>
        <Box>
          <Box top>
            <FigmaText styleTextKey={Texts.textPaymentsReceiptReceiptPageH1} />
          </Box>
          {enableSuccessMessage && <MessageSuccess textKey={Texts.textNotificationsNotificationSuccessfulPayment} />}
          <Box top>
            <EmailReceipt
              totalCostElement={
                <TotalCostContainer fullPadding direction="row" justify="space-between" fullWidth align="center">
                  <FigmaText textKey={Texts.textPaymentsReceiptReceiptTotalCost} />
                  <Box direction="row" align="center">
                    <FigmaText
                      textKey={Texts.textPaymentsReceiptCssReceiptTotalCostNumber}
                      text={formatPrice(session?.parkingSession?.currentPayment?.fees?.totalFeeIncludedVAT)}
                    />
                  </Box>
                </TotalCostContainer>
              }
              buttonElement={ButtonElement}
              isDesktop={isDesktop}
              enableSupportFooter={enableSupportFooter}
              session={session}
              Container={Container}
            />
          </Box>
        </Box>
      </Box>
    </LayoutComponent>
  )
}

const Container = styled(Box)`
  border-radius: var(--radius-radius-rounded, 1rem);
  border: 1px solid var(--border-border-primary, #d3d3d3);
  background: #fff;
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.1);
  padding: ${Spacings.s};
`

const TotalCostContainer = styled(Box)`
  border-radius: var(--radius-radius-rounded, ${BorderRadiusesPx.rounded});
  background: var(--color-purple-purple-0, ${Colors.purple0});
`
