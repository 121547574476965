import { Spacings } from '@contracts/enums/Spacings'

import Box from './Box'
import { Layout } from './Layout'
import Loader from './Loader'

export function LoadingPage({ LayoutComponent = Layout }: { LayoutComponent?: React.FC<any> }) {
  return (
    <LayoutComponent fullWidth>
      <Box fullWidth align="center" top spacing={Spacings.xxxl}>
        <Loader />
      </Box>
    </LayoutComponent>
  )
}
