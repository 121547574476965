import invariant from 'invariant'
import React from 'react'
import { useForm } from 'react-hook-form'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'
import { Spacings } from '@contracts/enums/Spacings'

import Texts from '@pure/assets/PayTexts'
import { isFreeSession } from '@pure/libs/SessionHelper'

import { setSentryUser } from '@web-js/libs/SentryHelper'
import { slack } from '@web-js/libs/SlackHelperClient'

import { HomeViewProps } from '@pay/libs/HomeHelper'

import ActiveSessionWidget from './ActiveSessionWidget'
import ActiveSessionWidgetFree from './ActiveSessionWidgetFree'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { Layout } from './Layout'

export const HomeUnpayedSession = (props: HomeViewProps) => {
  const { homeMachineState } = props

  const { session } = homeMachineState.data

  const isFree = isFreeSession(session || undefined)
  const ActiveSessionWidgetComponent = isFree ? ActiveSessionWidgetFree : ActiveSessionWidget
  const shouldShowPayment = !isFree
  invariant(session, '!session')

  const formProps = useForm({ defaultValues: { regNo: '' } })

  const onSubmit = formProps.handleSubmit(async () => {
    await slack(`Pay button clicked for session: ${session?.id}`, SlackWebhooks.OPS_HIKER_PAY_QR)
    return props.onPressContinue({
      ...homeMachineState,
      data: { ...homeMachineState.data }
    })
  })

  React.useEffect(() => {
    if (!session.id) return
    setSentryUser({ id: session.id })
  }, [session.id])

  return (
    <Layout onClickHeaderLogo={props.onClickHeaderLogo}>
      <Box fullWidth fullPadding>
        {!isFree && (
          <Box bottom>
            <FigmaText textKey={Texts.textPaymentsUnpaidSessionH1} />
          </Box>
        )}
        <Box fullWidth>
          <ActiveSessionWidgetComponent session={session} />
        </Box>
        {shouldShowPayment && (
          <Box fullWidth>
            <Box fullWidth top>
              <Box fullWidth right left spacing={Spacings.l}>
                <Button
                  textKey={Texts.textButtonsPaymentSelectionButtonText}
                  onClick={onSubmit}
                  fullWidth
                  loading={props.isLoading}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  )
}
