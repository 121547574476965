export const HardCodedTexts = {
  textPaymentsReceiptFreeParking: {
    characters: '',
    name: 'textPaymentsReceiptCssReceiptDetailsStreetName',
    texts: {
      swe: 'Gratis Parkering: XXX min per dag',
      eng: 'Free Parking: XXX min per day'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(124, 124, 124, 1)',
      opacity: 0.6000000238418579
    },
    pageName: 'Payment Flows QR'
  }
}
